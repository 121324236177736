body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  background-color: #fff;
  margin: 0;
}
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

header {
  text-align: center;
  color: aliceblue;
  background-color: #222;
  height: 60px;
  padding: 10px;
  padding-top: 4px;
  color: white;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}
.box {
  text-align: center;
  width: 50vw;
  margin: 10px;
  padding: 10px;
  /* border: 1px solid red; */
}

.text__area {
  width: 30vw;
  height: 50vh;
  padding: 1rem;
  font-size: 18px;
  margin-top: 1rem;
  text-align: justify;
}
.result__textare {
  cursor: wait;
  background-color: #eee;
  opacity: 1;
}

textarea:focus {
  outline: none !important;
  border: 1px solid blue;
  box-shadow: 0 0 20px #719ece;
  border-radius: 3%;
}

.msgSuccess {
  color: green;
  display: inline;
  margin-left: 10px;
  font-size: 1rem;
}

.center {
  text-align: center;
}
.left {
  text-align: left;
}
.right {
  float: right;
}

.selection {
  font-size: 1rem;
  line-height: 1.5;
}
.selection > select {
  cursor: pointer;
  padding: 0.275rem 2rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  border-radius: 50%;
  font: 16px Arial;
  margin-top: 4px;
}
.m-t-5 {
  margin-top: 5px;
}

.btn {
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.275rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-clear {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
@media (min-width: 1200px) {
  .container {
    width: 90%;
  }
  .text__area {
    width: 30vw;
    height: 50vh;
    padding: 1rem;
    border-radius: 3%;
  }
}

@media (min-width: 992px) {
  .container {
    width: 90%;
  }
  .text__area {
    width: 40vw;
    height: 50vh;
    padding: 1rem;
    border-radius: 3%;
  }
}

@media (min-width: 768px) {
  .container {
    width: 90%;
  }
  .text__area {
    width: 40vw;
    height: 50vh;
    padding: 1rem;
    border-radius: 3%;
    border: 1px solid #999;
  }
}
